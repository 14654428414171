import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from './components/homepage/homepage';
import Products from './components/products/products';
import Admin from "./components/products/addproducts";
import Cart from "./components/cart/cart";
import { CartProvider } from "./components/cart/cartprovider";
import Menu from "./components/menu/menu";
import Login from "./components/login/login";
import { auth } from "./firebaseconfig";
import { onAuthStateChanged, signOut } from "firebase/auth";  // Firebase auth imports
import StripeCheckout from "./components/checkout/checkout";
import PaymentFailed from "./components/checkout/paymentfailed";
import PaymentSuccess from "./components/checkout/paymentsuccess";
import ProductDetail from "./components/products/productdetail";
import CheckoutDetails from "./components/checkout/checkoutdetails";

function App() {
    const [user, setUser] = useState(null);

    const [products, setProducts] = useState(() => {
        // Retrieve products from localStorage if available
        const savedProducts = localStorage.getItem('products');
        return savedProducts ? JSON.parse(savedProducts) : [];
    });


    useEffect(() => {
        // Save products to localStorage whenever they change
        localStorage.setItem('products', JSON.stringify(products));
    }, [products]);

    const addProduct = (newProduct) => {
        setProducts([...products, newProduct]);
        console.log('Product added:', newProduct);  // Add this line to debug
    };
        // Track authentication state
        useEffect(() => {
            const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
                if (currentUser) {
                    setUser(currentUser);  // If a user is logged in, set the user state
                } else {
                    setUser(null);  // If no user is logged in, set the user state to null
                }
            });
    
            return () => unsubscribe();  // Clean up the listener when the component unmounts
        }, []);
    // Function to handle logout
    const handleLogout = async () => {
        try {
            await signOut(auth);  // Sign out the user
            alert("You have been logged out");
        } catch (error) {
            console.error("Error logging out:", error);
        }
    };
    return (
        <CartProvider>  {/* Wrap the entire app in CartProvider */}

        <Router>
        <Menu user={user} onLogout={handleLogout} />

            <Routes>
            <Route path="/login" element={<Login onLogin={setUser} />} />

                <Route path="/" element={<Homepage />} />
                <Route path="/products" element={<Products products={products}/>} />
                <Route path="/admin" element={<Admin addProduct={addProduct} />} />
                <Route path="/cart" element={<Cart />} />  
                <Route path="/checkout" element={<StripeCheckout />} />  {/* Add Checkout route */}
                <Route path="/payment-success" element={<PaymentSuccess />} />
                <Route path="/payment-failed" element={<PaymentFailed />} />
                <Route path="/product/:productId" element={<ProductDetail />} />  {/* Product detail route */}
                <Route path="/checkout-details" element={<CheckoutDetails />} />

            </Routes>
        </Router>   
        </CartProvider> 
    );
}

export default App;
