import React from 'react';

function PaymentFailed() {
    return (
        <div>
            <h1>Payment Failed</h1>
            <p>There was an issue with your payment. Please try again.</p>
        </div>
    );
}

export default PaymentFailed;
