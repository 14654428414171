import React, { useState,useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import './checkoutdetails.css';

const CheckoutDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { cartItems } = location.state;  // Retrieve cart items from state
    const [formData, setFormData] = useState({
      fullName: "",
      email: "",
      address: "",
      city: "",
      postalCode: "",
      country: "SE",  // Default to Sweden
    });
    const [totalAmount, setTotalAmount] = useState(0);  // Dynamic total amount
  
    // Calculate total amount dynamically based on cartItems
    useEffect(() => {
      const total = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
      setTotalAmount(total);
    }, [cartItems]);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Navigate to StripeCheckout, passing customer details and totalAmount
      navigate('/checkout', { state: { customerDetails: formData, totalAmount } });
    };
  
    return (
      <div className="checkout-details-container">
        <h2>Enter Shipping Details</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="fullName">Full Name</label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
          </div>
  
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
  
          <div className="form-group">
            <label htmlFor="address">Address</label>
            <input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </div>
  
          <div className="form-group">
            <label htmlFor="city">City</label>
            <input
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
            />
          </div>
  
          <div className="form-group">
            <label htmlFor="postalCode">Postal Code</label>
            <input
              type="text"
              id="postalCode"
              name="postalCode"
              value={formData.postalCode}
              onChange={handleChange}
              required
            />
          </div>
  
          <div className="form-group">
            <label htmlFor="country">Country</label>
            <input
              type="text"
              id="country"
              name="country"
              value={formData.country}
              onChange={handleChange}
              required
            />
          </div>
  
          <div className="cart-total">
            <h3>Total Amount: {totalAmount.toFixed(2)} Kr</h3>
          </div>
  
          <button type="submit">Proceed to Payment</button>
        </form>
      </div>
    );
  };
  
  export default CheckoutDetails;