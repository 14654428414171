import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import './admin.css';  // Import the CSS file for styling

function Admin() {
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [description, setDescription] = useState('');
    const [stock, setStock] = useState('');
    const [imageUrls, setImageUrls] = useState(['']);  // State to store image URLs
    const [url, setUrl] = useState('');  // URL to the product's webpage
    const [unitLabel, setUnitLabel] = useState('');  // For unit label, e.g., 'kg', 'item'
    const [type, setType] = useState('good');  // Either 'good' or 'service'
    const [shippable, setShippable] = useState(true);  // Whether the product is shippable
    const [isSubmitting, setIsSubmitting] = useState(false);  // For handling submit state
    const [isDeleting, setIsDeleting] = useState(false);
    const [products, setProducts] = useState([]);  // State for storing products

    const navigate = useNavigate();

    // Function to handle adding a new image URL input field
    const addImageUrlField = () => {
        setImageUrls([...imageUrls, '']);
    };
    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const response = await fetch('https://api.jatillforsvar.se/api/stripe-products');  // Adjust URL if necessary
            const data = await response.json();
            setProducts(data);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    // Function to remove an image URL input field
    const removeImageUrlField = (index) => {
        const newImageUrls = imageUrls.filter((_, i) => i !== index);
        setImageUrls(newImageUrls);
    };

    // Function to handle changes in the image URL input fields
    const handleImageUrlChange = (index, value) => {
        const newImageUrls = imageUrls.map((url, i) => (i === index ? value : url));
        setImageUrls(newImageUrls);
    };

    const handleDeleteProduct = async (productId) => {
        if (window.confirm("Are you sure you want to delete this product?")) {
            setIsDeleting(true);
            try {
                const response = await fetch(`https://api.jatillforsvar.se/api/delete-stripe-product/${productId}`, {
                    method: 'DELETE',
                });
    
                if (!response.ok) {
                    // If the response is not ok, handle the error
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Failed to delete product.');
                }
    
                const result = await response.json();
    
                if (result.success) {
                    alert('Product deleted successfully.');
                    // Refetch products after deletion
                    fetchProducts();
                } else {
                    alert('Error deleting product: ' + result.error);
                }
            } catch (error) {
                console.error('Error deleting product:', error);
                alert('Error deleting product: ' + error.message);
            } finally {
                setIsDeleting(false);
            }
        }
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Validate that at least one image URL is provided
        if (imageUrls.length === 0 || imageUrls.some(url => url.trim() === '')) {
            alert("Please provide at least one valid image URL.");
            return;
        }
    
        // Validate that the URL is not empty if provided
        if (url && url.trim() === '') {
            alert("Please provide a valid product URL.");
            return;
        }
    
        setIsSubmitting(true);  // Set submitting state
    
        try {
            // Send the product data (name, price, etc.) to your backend to create a Stripe product
            const productResponse = await fetch('https://api.jatillforsvar.se/api/create-stripe-product', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    name,
                    price,
                    description,
                    stock,
                    images: imageUrls,  // Send the image URLs directly
                    url: url.trim(),  // Product page URL
                    unit_label: unitLabel,  // Unit label like 'kg', 'item'
                    type,  // Either 'good' or 'service'
                    shippable  // Whether the product is shippable
                }),
            });
    
            const productData = await productResponse.json();
            
            if (productData.success) {
                const productId = productData.product.id;  // Capture the product ID
    
                alert(`Product added successfully to Stripe with ID: ${productId}`);
                
                // If you need to navigate or store this product ID, you can use the productId variable here
                navigate("/products");
            } else {
                alert("Error adding product: " + productData.error);
            }
        } catch (error) {
            console.error("Error adding product:", error);
            alert("Error adding product: " + error.message);
        } finally {
            setIsSubmitting(false);  // Reset submitting state
        }
    };
    
    

    return (
        <div className="admin-container">
            <h1>Add Product</h1>
            <form onSubmit={handleSubmit} className="admin-form">
                <div className="form-group">
                    <label>Product Name:</label>
                    <input 
                        type="text" 
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Price:</label>
                    <input 
                        type="number" 
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Description:</label>
                    <input 
                        type="text" 
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label>Stock:</label>
                    <input 
                        type="number" 
                        value={stock}
                        onChange={(e) => setStock(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Product URL:</label>
                    <input 
                        type="text" 
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        placeholder="Optional URL for the product"
                    />
                </div>
                <div className="form-group">
                    <label>Unit Label (e.g., kg, item):</label>
                    <input 
                        type="text" 
                        value={unitLabel}
                        onChange={(e) => setUnitLabel(e.target.value)}
                        placeholder="Optional unit label for pricing"
                    />
                </div>
                <div className="form-group">
                    <label>Type:</label>
                    <select value={type} onChange={(e) => setType(e.target.value)}>
                        <option value="good">Good</option>
                        <option value="service">Service</option>
                    </select>
                </div>
                <div className="form-group checkbox-group">
                    <label>Shippable:</label>
                    <input 
                        type="checkbox" 
                        checked={shippable}
                        onChange={() => setShippable(!shippable)}
                    />
                    <span>This product is shippable</span>
                </div>

                {/* Image URL Inputs with Thumbnail Previews */}
                <div className="form-group">
                    <label>Product Images (URLs):</label>
                    {imageUrls.map((url, index) => (
                        <div key={index} className="image-url-group">
                            <input
                                type="text"
                                placeholder="Enter image URL"
                                value={url}
                                onChange={(e) => handleImageUrlChange(index, e.target.value)}
                                required
                            />
                            {url && (
                                <img 
                                    src={url} 
                                    alt={`Thumbnail ${index}`} 
                                    className="thumbnail"
                                />
                            )}
                            {imageUrls.length > 1 && (
                                <button 
                                    type="button" 
                                    onClick={() => removeImageUrlField(index)} 
                                    className="remove-btn"
                                >
                                    Remove
                                </button>
                            )}
                        </div>
                    ))}
                    <button type="button" onClick={addImageUrlField} className="add-btn">
                        Add Another Image URL
                    </button>
                </div>

                <button type="submit" disabled={isSubmitting} className="submit-btn">
                    {isSubmitting ? 'Submitting...' : 'Add Product'}
                </button>
            </form>

             {/* List of Products */}
             <div style={{ marginTop: '40px' }}>
                <h2>Current Products</h2>
                {products.length === 0 ? (
                    <p>No products available</p>
                ) : (
                    <ul>
                        {products.map(product => (
                            <li key={product.id}>
                                <h3>{product.name}</h3>
                                <p>Price: {product.price} SEK</p>
                                <p>Stock: {product.metadata ? product.metadata.stock : 'N/A'}</p>
                                <div>
                                    <img src={product.images[0]} alt={product.name} width="100" />
                                </div>
                                <button 
                                    onClick={() => handleDeleteProduct(product.id)} 
                                    style={{ backgroundColor: 'red', color: 'white' }}
                                    disabled={isDeleting}
                                >
                                    {isDeleting ? 'Deleting...' : 'Delete Product'}
                                </button>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
}

export default Admin;
