// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";  // Add storage

const firebaseConfig = {
    apiKey: "AIzaSyAtLcpAvISnpWuyB_ePWn4_Ksw9CY6bGB0",
    authDomain: "jatillforsvar.firebaseapp.com",
    projectId: "jatillforsvar",
    storageBucket: "jatillforsvar.appspot.com",
    messagingSenderId: "77899653227",
    appId: "1:77899653227:web:36ad43b872bad2d5e1512e",
    measurementId: "G-8J3C5QNV5R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and Firestore
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);