import React from 'react';

function PaymentSuccess() {
    return (
        <div>
            <h1>Payment Successful</h1>
            <p>Thank you for your purchase!</p>
        </div>
    );
}

export default PaymentSuccess;
