import React, { useContext } from "react";
import { Link } from 'react-router-dom';
import { CartContext } from "../cart/cartprovider";  // Import CartContext



function Menu({ user, onLogout }) {
    const { cartItems } = useContext(CartContext);  

    const cartItemCount = cartItems.reduce((total, item) => total + item.quantity, 0);
    
    return (
        <div className="menu-container">
            <nav>
                <ul>
                    <li>
                        <Link to="/">Hem</Link>
                    </li>
                    <li>
                        <Link to="/products">Butik</Link>
                    </li>
                    <li>
                        <Link to="/cart">Varukorg ({cartItemCount})</Link>
                    </li>
                    {user ? (
                        <>
                            <li>
                                <Link to="/admin">Admin</Link>
                            </li>
                            <li>
                                <button onClick={onLogout}>Logga Ut</button>
                            </li>
                        </>
                    ) : (
                        <li>
                            <Link to="/login">Logga in</Link>
                        </li>
                    )}
                </ul>
            </nav>
        </div>
    );
}
export default Menu;