import React, { useContext, useState, useEffect } from "react";
import { CartContext } from "./cartprovider";
import { useNavigate } from "react-router-dom";

function Cart() {
    const { cartItems, removeFromCart } = useContext(CartContext);  // Add removeFromCart from context
    const [totalAmount, setTotalAmount] = useState(0);
    const navigate = useNavigate();

    // Calculate total amount whenever cartItems change
    useEffect(() => {
        const total = cartItems.reduce((acc, item) => acc + (item.price * item.quantity), 0);
        setTotalAmount(total);
    }, [cartItems]);

    const handleCheckout = () => {
        // Pass cartItems and totalAmount to the checkout details page
        navigate('/checkout-details', { state: { cartItems, totalAmount } });
    };
    


    return (
        <div className="cart-container">
            <h1>Your Cart</h1>
            {cartItems.length > 0 ? (
                <>
                    <ul>
                        {cartItems.map((item, index) => (
                            <li key={index} className="cart-item">
                                <img 
                                    src={item.images && item.images.length > 0 ? item.images[0] : '/no-image.png'} 
                                    alt={item.name} 
                                />
                                <div className="cart-item-details">
                                    <h2>{item.name}</h2>
                                    <p>Pris: {item.price} Kr</p>
                                    <p>Antal: {item.quantity}</p>
                                    <p>Total: {(item.price * item.quantity).toFixed(2)} Kr</p>
                                </div>
                                <button 
                                    className="remove-button"
                                    onClick={() => removeFromCart(item.id)}
                                >
                                    Remove Item
                                </button>
                            </li>
                        ))}
                    </ul>
                    <div className="cart-summary">
                        <h2>Total: {totalAmount.toFixed(2)} Kr</h2>
                        <button 
                            className="checkout-button" 
                            onClick={handleCheckout} 
                            disabled={cartItems.length === 0}
                        >
                            Checkout
                        </button>
                    </div>
                </>
            ) : (
                <p>Your cart is empty.</p>
            )}
        </div>
    );
}

export default Cart;
