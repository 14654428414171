import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useLocation } from 'react-router-dom';  // To access the passed state

// Initialize Stripe with your public key
const stripePromise = loadStripe('pk_test_51Q0ejhP59QRehSD0ocNzLrchQG5ctHEbgAQ6CbKhIQM2ullpNQiXPJx6HPcwVvuQgEb0qtnQ1yakBHZhvxOa9sX300MkFJDM5x');

const CheckoutForm = ({ clientSecret, totalAmount, customerDetails }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!stripe || !elements) {
            setErrorMessage('Stripe has not loaded yet.');
            setLoading(false);
            return;
        }

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: 'http://localhost:3000/payment-success',  // Redirect URL after payment success
                payment_method_data: {
                    billing_details: {
                        name: customerDetails.fullName,
                        email: customerDetails.email,
                        address: {
                            line1: customerDetails.address,
                            city: customerDetails.city,
                            postal_code: customerDetails.postalCode,
                            country: customerDetails.country,
                        },
                    },
                },
            },
        });

        if (error) {
            setErrorMessage(error.message);
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="checkout-form-container">
            <h2>Checkout</h2>
            {clientSecret ? (
                <>
                    <PaymentElement className="PaymentElement" />
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                    <button disabled={!stripe || loading}>
                        {loading ? 'Processing...' : `Pay ${totalAmount} SEK`}
                    </button>
                </>
            ) : (
                <div>Loading payment options...</div>
            )}
        </form>
    );
};

const StripeCheckout = () => {
    const location = useLocation();
    const { customerDetails, totalAmount } = location.state || {};  // Extract customerDetails and totalAmount
    const [clientSecret, setClientSecret] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchClientSecret = async () => {
            try {
                const response = await fetch('https://api.jatillforsvar.se/create-payment-intent', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        amount: totalAmount * 100,  // Convert to cents
                        currency: 'sek',
                        customerDetails,  // Send customer details to backend
                    }),
                });

                const data = await response.json();
                if (response.ok) {
                    setClientSecret(data.clientSecret);
                } else {
                    throw new Error(data.error || 'Failed to retrieve client secret.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchClientSecret();
    }, [totalAmount, customerDetails]);

    if (loading) {
        return <div>Loading payment options...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const options = {
        clientSecret,
        locale: 'sv',  // Swedish locale
    };

    return (
        <Elements stripe={stripePromise} options={options}>
            <CheckoutForm clientSecret={clientSecret} totalAmount={totalAmount} customerDetails={customerDetails} />
        </Elements>
    );
};

export default StripeCheckout;
