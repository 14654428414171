import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './productdetail.css'; // Create and import a CSS file for styling
function ProductDetail() {
    const { productId } = useParams(); // Fetch product ID from URL params
    const [product, setProduct] = useState(null); // Product state
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    const [selectedImage, setSelectedImage] = useState(null); // State for the currently selected large image

    // Fetch product details when component mounts
    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await fetch(`https://api.jatillforsvar.se/api/get-stripe-product/${productId}`);
                const data = await response.json();
                setProduct(data); // Set product data
                setSelectedImage(data.images[0]); // Set the first image as default
                setLoading(false); // Set loading to false when done
            } catch (err) {
                setError('Failed to fetch product details');
                setLoading(false); // Stop loading in case of an error
            }
        };

        fetchProduct();
    }, [productId]);

    if (loading) return <div>Loading...</div>; // Display loading indicator
    if (error) return <div>Error: {error}</div>; // Display error message if any

    // Ensure product is defined before rendering
    if (!product) return <div>No product found</div>;

    return (
        <div className="product-detail">
            <div className="product-header">
                <h1>{product.name}</h1>
                <p className="price">Price: {product.price / 100} SEK</p>
            </div>
            
            <div className="product-body">
                <p><strong>Description:</strong> {product.description}</p>
                <p><strong>Type:</strong> {product.type}</p>
                <p><strong>Stock:</strong> {product.metadata?.stock || 'Not available'}</p>
                <p><strong>Shippable:</strong> {product.shippable ? 'Yes' : 'No'}</p>
                <p><strong>URL:</strong> {product.url ? <a href={product.url} target="_blank" rel="noopener noreferrer">{product.url}</a> : 'N/A'}</p>
            </div>

            {/* Large selected image */}
            <div className="selected-image-container">
                <img src={selectedImage} alt="Selected Product" className="selected-image" />
            </div>

            {/* Thumbnail images */}
            {product.images && product.images.length > 0 ? (
                <div className="product-thumbnails">
                    {product.images.map((imageUrl, index) => (
                        <img
                            key={index}
                            src={imageUrl}
                            alt={`Product image ${index + 1}`}
                            className={`thumbnail-image ${selectedImage === imageUrl ? 'active' : ''}`}
                            onClick={() => setSelectedImage(imageUrl)} // Set clicked image as the large image
                        />
                    ))}
                </div>
            ) : (
                <p>No images available</p>
            )}
        </div>
    );
}

export default ProductDetail;