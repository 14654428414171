import React, { useEffect, useState, useContext } from 'react';
import { db, auth, storage } from '../../firebaseconfig';  // Import Firebase Auth for admin check
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";  // Firestore imports
import { CartContext } from '../cart/cartprovider';
import { ref, deleteObject } from "firebase/storage";
import { Link } from 'react-router-dom';
function Products() {
    const [products, setProducts] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);  // State to track if the user is an admin
    const { addToCart } = useContext(CartContext);

    // Check if the user is an admin
    useEffect(() => {
        const checkAdminStatus = async () => {
            const user = auth.currentUser;  // Get the current logged-in user
            if (user) {
                const adminEmails = ['seuan.rahman@gmail.com'];  // List of admin emails
                setIsLoggedIn(adminEmails.includes(user.email));  // Check if the user's email is in the admin list
            }
        };
        checkAdminStatus();
    }, []);

    // Fetch products from the backend
    useEffect(() => {
        const fetchProducts = async () => {
            try {
               // const response = await fetch('http://85.214.10.78:4242/api/stripe-products');  // Adjust the URL to your backend
                const response = await fetch('https://api.jatillforsvar.se/api/stripe-products');  // Adjust the URL to your backend
                const data = await response.json();
                setProducts(data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);
    // Function to remove product from Firestore and associated images from Storage
    const removeProduct = async (product) => {
        if (window.confirm("Are you sure you want to remove this product? This will delete all associated images.")) {
            try {
                // 1. Delete associated images from Firebase Storage
                if (product.images && product.images.length > 0) {
                    const deletePromises = product.images.map(async (imageUrl) => {
                        if (imageUrl) {
                            const imagePath = getStoragePathFromUrl(imageUrl);  // Extract the storage path
                            if (imagePath) {
                                const imageRef = ref(storage, imagePath);  // Reference to the image in storage
                                await deleteObject(imageRef);  // Delete the image from Firebase Storage
                            }
                        }
                    });

                    // Wait for all images to be deleted
                    await Promise.all(deletePromises);
                }

                // 2. Delete the product from Firestore
                await deleteDoc(doc(db, "products", product.id));

                // 3. Remove product from the UI by filtering it out of state
                setProducts(prevProducts => prevProducts.filter(p => p.id !== product.id));

                alert("Product and associated images removed successfully!");
            } catch (error) {
                console.error("Error removing product:", error);  // Log the error for better debugging
                alert("Error removing product: " + error.message);
            }
        }
    };

    // Utility function to extract storage path from a Firebase Storage URL
    const getStoragePathFromUrl = (url) => {
        const baseUrl = "https://firebasestorage.googleapis.com/v0/b/jatillforsvar.appspot.com/o/";

        // Check if the URL is valid before attempting to extract the path
        if (url && url.indexOf(baseUrl) === 0) {
            return decodeURIComponent(url.replace(baseUrl, '').split('?')[0]);
        } else {
            console.error("Invalid or undefined URL:", url);
            return null;
        }
    };

    return (
        <div className="products-section">
            {products.map(product => (
                <ProductCard key={product.id} product={product} isLoggedIn={isLoggedIn} removeProduct={removeProduct} addToCart={addToCart} />
            ))}
        </div>
    );
}

function ProductCard({ product, isLoggedIn, removeProduct, addToCart }) {
    const [selectedImage, setSelectedImage] = useState(product.images && product.images.length > 0 ? product.images[0] : '/no-image.png');

    return (
        <div className="product-card">
            <h2>{product.name}</h2>
            <img src={selectedImage} alt={product.name} />
            <p>Pris: {product.price} Kr</p>
            <p>Beskrivning: {product.description}</p>
            <p>Antal: {product.metadata.stock}</p>

            {/* Image Thumbnails */}
            <div className="thumbnail-container">
                {product.images && product.images.length > 0 && product.images.map((imageUrl, index) => (
                    <img
                        key={index}
                        src={imageUrl}
                        alt={`Thumbnail ${index}`}
                        className={`thumbnail ${imageUrl === selectedImage ? 'selected' : ''}`}
                        onClick={() => setSelectedImage(imageUrl)}  // Update the selected image
                    />
                ))}
            </div>

            {/* Add "Add to Cart" button */}
            <button onClick={() => addToCart(product)}>Lägg till i Varukorg</button>

            <Link to={`/product/${product.id}`}>
                            <button style={{ marginTop: '10px' }}>
                                View Details
                            </button>
                        </Link>
        </div>
    );
}

export default Products;
