import React from 'react';
import Menu from '../menu/menu';

export default function Homepage() {
    return (
        <div>
            <h1>Welcome to the Homepage</h1>
        </div>
    );
}
